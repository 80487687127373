/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  /*color: white;*/
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: "Josefin Sans", sans-serif;
  /*font-family: "Ropa Sans", sans-serif;*/
  background-color: #f7f7f7;
}

.navbar {
  /*border-bottom: 1px solid rgba(0,0,0,0.05);*/
  margin-bottom: 10px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 6px 6px;
}

.badge {
  padding-top: 4px;
  padding-bottom: 4px;
}

.form-control {
  margin-bottom: 8px;
  background-color: #fafafa;
}

.form-control::placeholder {
  color: #b7b7b7;
}

/*.centered-on-img {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    background-color: white;*/
/*    padding-left: 12px;*/
/*    padding-right: 12px;*/
/*    border-radius: 10px;*/
/*}*/

.card {
  /*font-family: "PT Sans", sans-serif;*/
  /*font-family: 'Ropa Sans', sans-serif;*/
  font-family: "Lato", sans-serif;
  /*  font-family: 'Roboto Slab', serif;*/
  /*font-family: "Roboto", sans-serif;*/
  /*color: white;*/
  /*background-color: rgba(0, 0, 0, 0.1);*/
  /*border: 1px rgba(0,0,0,0.08) solid;*/
  /*box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.04);*/
  border: 0;
  box-shadow: 0px 0px 8px 1px rgba(210, 210, 210, 0.2);
  border-radius: 8px;
  margin: 12px 0px;
}

.nav-link.active {
  /*text-decoration: underline;*/
  font-weight: bold;
}

.card.loading-card {
  background-color: #fafafa;
  padding: 50px;
}

h6 {
  font-weight: bold;
}

[type="button"]:not(:disabled) {
  padding: 0px;
  margin-top: 6px;
}

/*original custom link*/
.custom-link-hover {
  display: inline-block !important;
  margin-left: -5px;
  padding: 2px 5px 0px 5px;
}

.custom-link-hover:hover {
  color: #000000;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
}

.white-bg-link {
  opacity: 0.4;
  /*display: inline-block;*/
  border: 1px solid #fdfdfd;
  border-radius: 6px;
  font-size: 12px;
  background-color: white;
  color: black;
}

.btn:disabled {
  cursor: not-allowed;
}

/*superscript*/
sup {
  /*font-weight: 600;*/
  font-size: 0.9em;
  /*font-style: italic;*/
  color: #7e8a93;
}

/*.white-bg-link:hover {*/
/*  opacity: 0.9;*/
/*  background-color: white;*/
/*  color: #000000;*/
/*}*/

/*.white-bg-link:active {*/
/*  !*opacity: 0.9;*!*/
/*  background-color: rgba(255, 255, 255, 1) !important;*/
/*  color: black !important;*/
/*}*/

/*.empty-img-40 {*/
/*  !*border: 1px solid black;*!*/
/*  background-color: #dfdfdf;*/
/*  border-radius: 6px;*/
/*  height: 40px;*/
/*  width: 40px;*/
/*  display: inline-block;*/
/*}*/

.nav-item-loading {
  background-color: #f5f0f0;
  border-radius: 8px;
  color: #f5f0f0;
  margin: auto;
  width: 50%;
}

.gray-dot {
  height: 40px;
  width: 40px;
  background-color: #e5e5e5;
  border-radius: 50%;
  display: inline-block;
  margin-top: -50px;
  margin-bottom: 20px;
}

.gray-line {
  color: #e8e8e8;
  height: 20px;
  /*width: 400px;*/
  margin: auto;
  margin-top: 15px;
  background-color: #e8e8e8;
  border-radius: 8px;
}

.nav-pills.em-nav .nav-link:not(.active) {
  color: rgb(179, 179, 179);
  background-color: rgba(0, 0, 0, 0.04);
  /*border-top: 1px solid rgba(2,2,2,0.08);*/
  /*border-left: 1px solid rgba(2,2,2,0.04);*/
  /*border-right: 1px solid rgba(2,2,2,0.04);*/
  /*border-top-radius: 8px;*/
  /*border-radius: 8px;*/
}
.nav-pills.em-nav .nav-link.active {
  color: black;
  background-color: rgba(0, 0, 0, 0);
  /*border-top: 1px solid rgba(2,2,2,0.1);*/
  /*box-shadow: 0px -4px 6px 1px rgba(0, 0, 0, 0.04);*/
  /*border-bottom: 1px solid rgba(2,2,2,0.04);*/
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  /*border-radius: 8px;*/
  /*border: 1px solid #c6c6c6;*/
}

.custom-nav-btn {
  /*float: left;*/
  /*padding: 5px;*/
  /*margin: 8px 3px 0;*/
  font-size: 20px;
  background-color: #898989;
}

.light-btn-color {
  opacity: 0.3;
  font-size: 16px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.country-btn {
  font-family: "PT Sans", sans-serif;
  background-color: #e0e0e0;
  color: black;
  /*font-weight: bold;*/
  font-size: 16px;
  margin: 4px 5px;
  padding: 3px 9px !important;
  /*border: 0;*/
  border: 1px solid #e3e3e3;
}

.country-btn:hover {
  background-color: #fefefe;
  color: #272727;
  border: 1px solid #696969;
  /*font-weight: bold;*/
}

.w-85 {
  width: 85% !important;
}

.title-group-title {
  /*border-radius: 12px;*/
  /*box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.06);*/
  border-bottom: 1px solid #cccccc;
  color: #919191;
  font-weight: bold;
  /*font-size: 22px;*/
  /*margin-bottom: 2px;*/
  display: inline-block;
  /*display: flex;*/
  /*max-width: 50%;*/
  /*position: center;*/
  /*padding: 14px 60px 16px 60px;*/
  /*padding: 8px 0px;*/
  /*padding-bottom: 1px;*/
}

.global-numbers {
  display: flex;
  background-color: #f7f5f5;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  padding: 8px;
  margin: 0px 10px;
}

.top-group-img {
  max-width: 60px;
  /*display: flex;*/
  /*position: absolute;*/
}

.em-footer {
  /*position: absolute;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  /*height: 2.5rem;*/
}

.subtopic-card {
  box-shadow: 0px 0px 6px 0px rgba(210, 210, 210, 0.3);
  margin-top: 8px;
  /*border-radius: 10px;*/
  /*border: 1px solid rgba(0, 0, 0, 0.1);*/
}

/*@media (min-width: 544px) {*/
/*    .card-deck {*/
/*        display: flex;*/
/*        width: 100%;*/
/*    }*/
/*    .card-deck .card {*/
/*        display: flex;*/
/*        !*flex: 1 1 auto;*!*/
/*        flex-direction: column;*/
/*        justify-content: space-between;*/
/*    }*/
/*    .card-block {*/
/*        flex: 1 1 auto;*/
/*    }*/
/*}*/

/*.group-box-container {*/
/*    position: relative;*/
/*    display: inline-flex;*/
/*    !*margin-bottom: 15px;*!*/
/*    font-size: 22px;*/
/*    background-color: #ffffff;*/
/*    !* color: white; *!*/
/*    border-radius: 10px;*/
/*    padding: 8px;*/
/*}*/

/*.group-box-text {*/
/*    color: black;*/
/*    z-index: 999;*/
/*    !* top: 40%; *!*/
/*    background-color: white;*/
/*}*/

/*.group-box-image {*/
/*    margin: auto;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    opacity: .5;*/
/*    right: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*}*/

.btn-light {
  background-color: #e2e6ea;
  color: black;
}

hr {
  opacity: 0.5;
  margin-top: 4px;
  margin-bottom: 4px;
}

.valid-claim-box {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 6px 12px 6px 12px;
  /*display: inline-block;*/
  /*background-color: rgba(255, 162, 41, 0.1);*/
  background-color: rgba(255, 162, 41, 0.1);
  font-weight: 700;
  border-radius: 10px;
  line-height: 1;
  /*font-size: large;*/
  /*box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);*/
  /*box-shadow: 0px 0px 10px 1px rgba(210, 210, 210, 0.5);*/
  margin: 2px 16px 8px 16px;
}

.valid-claim-box .badge {
  margin-top: 6px;
}

.title-top-box {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px 10px 4px 10px;
  display: inline-block;
  background-color: rgba(238, 238, 238, 0.4);
  color: #6c6c6c;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /*box-shadow: 0px -2px 4px 1px rgba(0, 0, 0, 0.04);*/
  /*margin: 4px 18px 0px 18px;*/
  /*font-size: large;*/
}

.gentle-outline {
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 12px 14px;
  margin: 0px -8px 12px -8px;
  font-size: 22px;
  background: white;
  display: block !important;
}

.gentle-outline-x {
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 16px;
  /*display: block !important;*/
}

.col-form-label {
  font-size: 12px;
  text-align: left;
}

.title-outline-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 7px 0px 4px 0px;
  margin: 12px 12px;
}

.gentle-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  border-radius: 6px;
  /*margin: 12px 10px;*/
  padding: 12px 8px;
}

.gentle-border-soft {
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  /*background-color: #f7f7f7;*/
  /*  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);*/
  border-radius: 10px;
  /*margin: 12px 10px;*/
  /*padding: 12px 8px;*/
}

.gentle-outline-no-shadow {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 12px 0px 6px 0px;
  margin: 0px -8px 14px -8px;
  /*background-color: rgba(0,0,0,0.02);*/
}

.custom-btn {
  padding: 5px;
  margin: 8px 0 0;
  border: 0;
  height: 20%;
  font-size: 12.25px;
}

/*.gentle-outline-outermost {*/
/*  border: 1px solid rgba(0, 0, 0, 0.05);*/
/*  !*box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.04);*!*/
/*  border-radius: 12px;*/
/*  padding: 12px 8px 6px 8px;*/
/*  margin: 0px -8px;*/
/*  !*background-color: #FFFFFF;*!*/
/*}*/

.outline-link-box {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 3px 12px 0px 12px;
  display: inline-block;
}

.footer-special {
  border: 0px;
  /*margin-top: 4px;*/
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0.5;
}

/*MODAL ADJUSTMENTS*/
.modal.fade .modal-dialog {
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
}

/*fixes modal shift issue*/
.modal {
  overflow: auto;
}

.modal-dialog {
  position: absolute;
  /*top: 22px;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*margin-top: auto;*/
  /*margin-bottom: auto;*/
  max-width: 600px;
}

.modal-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.modal-open {
  overflow: auto;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-05 {
  opacity: 0.1 !important;
}
.opacity-1 {
  opacity: 0.2 !important;
}
.opacity-2 {
  opacity: 0.4 !important;
}
.opacity-3 {
  opacity: 0.6 !important;
}
.opacity-4 {
  opacity: 0.8 !important;
}
.opacity-5 {
  opacity: 1 !important;
}

.rounded-8 {
  border-radius: 8px;
}

.bg-gray-1 {
  background-color: #efefef;
}

.rectangle-gray {
  border-radius: 10px;
  background-color: rgb(228, 228, 228);
  padding: 22px;
}

.font-size-100 {
  font-size: 100%;
}

.line-h-1 {
  line-height: 1;
}

.line-h-2 {
  line-height: 1.2;
}

.font-sm {
  font-size: 0.6em !important;
}

span.badge {
  white-space: normal;
}

/* to disable the scroll getting stuck on Android mobile browsers*/
@media only screen and (max-width: 600px) {
  .VictoryContainer {
    user-select: auto !important;
    pointer-events: auto !important;
    touch-action: auto !important;
  }
}

/*to get fonts to work in firefox*/
/*@font-face {*/
/*  font-family: "PT Sans";*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url("https://fonts.googleapis.com/css?family=PT+Sans|PT+Sans+Caption|PT+Sans+Narrow&display=swap")*/
/*}*/

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.rounded-6 {
  border-radius: 8px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}

.font-size-xs {
  font-size: x-small;
}

.font-size-sm {
  font-size: small;
}

.font-size-md {
  font-size: medium;
}

.font-size-lg {
  font-size: large;
}

.font-size-xl {
  font-size: x-large;
}

.font-size-xxl {
  font-size: xx-large;
}

.text-lighter {
  color: rgba(0,0,0,0.2)
}
